.container {
    width: 80%;
    margin: auto;
    padding: 10px;
  }
  
  .left {
    width: 45%;
    display : inline-flex;
    margin-right: 10px;
  }
  
  .right {
    width: 50%;
    display : inline-flex;
  }

.card {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.new-cat-btn {
    display: flex;
    justify-content: center;
}